(function () {
  'use strict';

  class CloneModuleCtrl {
    constructor($mdDialog, Modules, ModuleSet, CloneModuleFlow) {
      let vm = this;
      vm.$mdDialog = $mdDialog;
      vm.Modules = Modules;
      vm.ModuleSet = ModuleSet;
      vm.node = CloneModuleFlow.getNode();
      vm.clone = {
        module_categories: []
      };
      vm.isClone = false;
      vm.moduleSetIndex();
    }
    cancel() {
      let vm = this;
      vm.$mdDialog.cancel();
    }
    setUpModuleClone() {
      const vm = this;
      angular.forEach(vm.selectedSet.module_categories, function (_, k, obj) {
        const category = {
          slug: obj[k].slug,
          value: obj[k].value,
          name: obj[k].name
        };
        vm.clone.module_categories.push(category);
      });
      vm.clone.module_set_id = vm.selectedSet.id;
    }
    resourceHandler() {
      const vm = this;
      if (vm.isClone) {
        vm.clone.resource = {
          concat_type: 'suffix'
        };
      } else {
        delete vm.clone.resource;
      }
    }
    hide(data) {
      let vm = this;
      vm.$mdDialog.hide(data);
    }
    moduleSetIndex() {
      const vm = this;
      vm.ModuleSet.moduleSetIndex()
        .then((data) => {
          vm.ModuleSetsPayload = data;
        });
    }
    submit(form) {
      let vm = this;
      if (form.$valid) {
        vm.setUpModuleClone();
        vm.hide(vm.clone);
      }
    }
  }

  /**
   * @ngdoc object
   * @name components.controller:CloneModuleCtrl
   *
   * @description
   *
   */
  angular
    .module('components')
    .controller('CloneModuleCtrl', CloneModuleCtrl);
}());
